/* eslint-disable max-classes-per-file */
import * as React from 'react';
import * as assign from 'lodash/assign';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader } from '@inwink/loader';
import type { Entities } from '@inwink/entities/entities';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { actions as pageActions } from '../../../../services/pageservice';
import { Page } from '../../../../components/page';
import { DynamicPage } from '../../../../components/dynamicpage/dynamicpage';
import type { IDefaultEventPageProps } from '../../../../data/visualtheme';
import type { States } from '../../../../services/services';

import './persondetail.less';

function getApi() {
    return import('../../api/networking');
}

export interface IPersonDetailPageProps extends IDefaultEventPageProps {
    routes: any[];
    pageActions?: typeof pageActions;
    personType?: 'person' | 'speaker';
    pages?: States.IPagesState;
}

const stdpagename = "persondetail";

export interface IPersonDetailPageContentProps extends IDefaultEventPageProps {
    personid: string;
    person: Entities.IPerson;
    navigateToNextOnContact: boolean;
    routes?: any[];
    pagename: string;
    pagetemplate?: Entities.IContentTemplate;
    pages?: States.IPagesState;
    isPublicContent?: boolean;
    pageActions?: typeof pageActions;
    customcontext?: Entities.IPageDataContext;
    personProvider? : (personid: string) => Promise<Entities.IPerson>;
    i18nHelper?: Entities.i18nHelper;
    personType?: 'person' | 'speaker';
}

@withI18nHelper()
class PersonDetailPageContentComponent extends React.Component<IPersonDetailPageContentProps, any> {
    pageInit: Promise<any>;

    constructor(props: IPersonDetailPageContentProps) {
        super(props);

        const personid = props.personid;

        const speaker = this.props.personType !== 'person' && this.props.event?.data?.speakers
            ? this.props.event.data.speakers.data.find((s) => s.id === personid) : null;

        this.pageInit = props.pageActions.initCurrentPage(
            { location: props.location, match: props.match },
            props.pagename,
            {
                entityKind: "Person",
                entity: speaker,
                entityid: personid,
                pageNotFound: __SERVERSIDE__ && !this.props.isPublicContent,
                pagetemplate: props.pagetemplate,
            }
        ) as any;

        this.state = {
            loading: !speaker,
            personid: personid,
            userDetail: speaker,
            isSpeaker: speaker ? true : undefined
        };
    }

    private refreshUserDetails(): Promise<any> {
        this.setState({ fetching: true });
        let promise;
        if (this.props.personProvider) {
            promise = this.props.personProvider(this.state.personid);
        } else {
            promise = getApi().then((api) => api.getPersonDetail(this.props.event.requestManagers, this.state.personid));
        }
        return promise.then((userdetail) => {
            const page = assign({}, this.props.pages["companion.page." + this.props.pagename]) as States.ICurrentPageState;
            const userdata = userdetail;

            page.context.entity = userdata;
            this.props.pageActions.setCurrentPage(page);
            return userdata;
        }).catch(() => {
            const page = assign({}, this.props.pages["companion.page." + this.props.pagename]) as States.ICurrentPageState;
            page.context.entity = this.props.person;
            this.props.pageActions.setCurrentPage(page);
            return Promise.resolve(this.props.person);
        });
    }

    getUserDetail(): Promise<any> {
        let user = null;
        if (this.props.navigateToNextOnContact) { // mode direct via le networking
            return this.refreshUserDetails();
        }

        if (this.props.personProvider) { // mode direct via le networking
            return this.refreshUserDetails();
        }

        if (this.state.personid && this.props.personType !== 'person') {
            // person type was speaker or exhibitor account
            const speaker = this.props.event?.data?.speakers
                ? this.props.event.data.speakers.data.find((s) => s.id === this.state.personid)
                : null;
            if (speaker) {
                user = speaker;
            }
        }

        if (user) {
            const page = assign({}, this.props.pages["companion.page." + this.props.pagename]) as States.ICurrentPageState;
            page.context.entity = user;
            this.props.pageActions.setCurrentPage(page);
            return Promise.resolve(user);
        }

        const setNotFound = () => {
            const page = assign({}, this.props.pages["companion.page." + this.props.pagename]) as States.ICurrentPageState;
            page.notFound = true;
            this.props.pageActions.setCurrentPage(page);
        };

        if (this.props.personType === 'speaker') {
            setNotFound();
            return Promise.resolve(null);
        }

        return this.refreshUserDetails().then((res) => {
            if (!res) {
                setNotFound();
            }
            return res;
        }, setNotFound);
    }

    getUserData() {
        if (this.props.pages.currentPageId !== "companion.page." + this.props.pagename) return;

        if (this.props.page?.context?.entity && this.props.navigateToNextOnContact === false) {
            this.setState({ loading: false, fetching: false, userDetail: this.props.page.context.entity, isSpeaker: true });
        } else if (this.props.user?.currentUser) {
            const { i18nHelper } = this.props;
            if (!this.state.message && !this.state.userDetail && !this.state.fetching) {
                this.getUserDetail().then((res) => {
                    if (res) {
                        this.setState({ loading: false, fetching: false, userDetail: res });
                    } else {
                        this.setState({
                            loading: false,
                            fetching: false,
                            userDetail: null,
                            message: i18nHelper.translate("persondetail.usernotfound")
                        });
                    }
                }, (fail) => {
                    fail.json().then((json) => {
                        let ms = "persondetail.error";
                        if (json.code === 101) {
                            ms = "persondetail.usernotallownetworking";
                        } else if (json.code === 102) {
                            ms = "login.registrationrequired";
                        }
                        this.setState({
                            loading: false,
                            fetching: false,
                            userDetail: null,
                            message: i18nHelper.translate(ms)
                        });
                    });
                });
            }
        }
    }

    componentDidMount() {
        if (this.props.page && this.props.page.ready && this.props.page.ready.then) {
            this.props.page.ready.then(() => {
                if (this.state.loading) {
                    this.getUserData();
                } else {
                    const page = assign({},
                        this.props.pages["companion.page." + this.props.pagename]) as States.ICurrentPageState;
                    this.props.pageActions.setCurrentPage(page);
                }
            });
        } else {
            this.pageInit.then((result) => {
                this.props.pageActions.setCurrentPage(result.page);
                if (this.state.loading) {
                    this.setState({ loading: false });
                }
            });
        }
    }

    componentDidUpdate() {
        if (!this.state.userDetail) {
            if (this.props.page && this.props.page.ready && this.props.page.ready.then) {
                this.props.page.ready.then(() => {
                    this.getUserData();
                });
            }
        }
    }

    render() {
        const classNames = ["persondetailpage"];
        const pageid = "companion.page." + this.props.pagename;
        const page = this.props.pages[pageid] as States.ICurrentPageState;
        let content;
        if (this.state.userDetail) {
            if (page && page.context.entity && page.context.entityid === this.state.userDetail.id) {
                content = <DynamicPage
                    key={"persondetail-" + this.state.userDetail.id}
                    template={pageid}
                    {...this.props}
                    requireEntity
                    customcontext={this.props.customcontext}
                />;
            }
        }

        if (this.state.message) {
            classNames.push("withmessage", "bloctheme");
            content = <div className="message bloc-defaultbg">
                {this.state.message}
            </div>;
        }

        return <Page {...this.props} allowedOnly={!this.state.isSpeaker} className={classNames.join(" ")}>
            <div className="pagecontent">
                {content}
                <Loader className={"big bloctheme " + (this.state.loading ? " active" : "inactive")} fullsize />
            </div>
        </Page>;
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        event: state.event,
        rootwebsite: state.rootwebsite,
        user: state.user,
        page: state.pages.currentPage,
        i18n: state.i18n,
        pages: state.pages
    };
}

function mapDispatchToProps(dispatch) {
    return {
        pageActions: bindActionCreators(pageActions, dispatch)
    };
}

export const PersonDetailPageContent: new (PersonDetailPageProps: IPersonDetailPageContentProps)
=> React.Component<IPersonDetailPageContentProps, any> = connect(
    mapStateToProps,
    mapDispatchToProps
)(PersonDetailPageContentComponent as any) as any;

class PersonDetailPageComponent extends React.Component<IPersonDetailPageProps, any> {
    constructor(props: IPersonDetailPageProps) {
        super(props);

        const personid = this.props.match && this.props.match.params.personid.toLowerCase();
        const speaker = props.event.data.speakers.data.filter((s) => s.id === personid)[0];
        let pagename = stdpagename;
        if (this.props.personType === 'speaker') {
            const speakerPageTemplate = (this.props.pageActions.getPageTemplate('companion', 'page', 'speakerdetail') as any);
            if (speakerPageTemplate) {
                pagename = 'speakerdetail';
            }
        }
        this.state = {
            isPublicContent: !!speaker,
            pagename: pagename
        };
    }

    render() {
        return <Page {...this.props} allowedOnly={!__SERVERSIDE__ && !this.state.isPublicContent} className="persondetailpage">
            <PersonDetailPageContent
                {...this.props}
                pagename={this.state.pagename}
                navigateToNextOnContact={false}
                isPublicContent={this.state.isPublicContent}
                personid={this.props.match.params?.personid?.toLowerCase()}
                person={this.props.match?.params?.person}
            />
        </Page>;
    }
}

export const PersonDetailPage: new (PersonDetailPageProps: IPersonDetailPageProps)
=> React.Component<IPersonDetailPageProps, any> = connect(
    mapStateToProps,
    mapDispatchToProps
)(PersonDetailPageComponent as any) as any;
